import React, { useEffect, useState } from 'react';
import styles from './style.module.scss';
import usePaginatedQuery from 'hooks/use-paginated-query';
import { LIST_VIEW, MAP_VIEW } from 'components/listing-filter-bar';
import { useMapContext } from 'contexts/map';
import mapSearchParams from 'utils/search-params';
import SidePanel from './side-panel';
import Map from 'components/search/map';
import useDebounce from 'hooks/use-debounce';
import { Boundary, useOverlayContext } from 'contexts/overlay';
import { createListingParams } from 'contexts/preferences/listing-params';
import { trackEvent } from 'utils/google-tag-manager';
import { useThemeContext, useModalContext, useUserContext, usePreferencesContext, SAVED_SEARCH_FILTERS_MODAL } from 'contexts';
import deepmerge from 'deepmerge';
import { captureLastSearch } from 'pages/api/last-search/lastSearchApiClient';
import { handleSearchResult } from 'components/search-bar/useSearchBar';
import MapListToggleButtonOnMap from 'components/listing-filter-bar/map_list_toggle_button';
import { useIsMobile } from 'hooks/use-size-class';
import { useRouter } from 'next/router';
import { formatQueryIntoURLQueryParams } from 'utils/listing-query-helper';
import { getClustersAndListings } from 'data/listing';
import { getSchools } from 'data/schools';
import { IsUserInQuebec, handleQuebecPopup } from 'utils/modals';
import { ListingFilterBar } from 'components/listing-filter-bar';

import type SearchPrediction from 'data/search-predictions';
import type Listing from 'data/listing';
import type { Cluster } from 'components/search/types';
import type { Sort } from 'contexts/preferences/listing-params/types';
import type School from 'data/schools';
import type { PartialDeep } from 'type-fest';
import type { Filter } from 'contexts/preferences/listing-params/types';
import type { Meta } from 'utils/types';
import type { ThemeNames } from 'types/themes';

interface Props {
  initialData: {
    listings: Listing[];
    clusters: Cluster[];
    meta: Meta;
  };
}

/** @deprecated use components from `components/search-es` instead */
const Search = ({ initialData }: Props) => {
  const { listingParams, schoolParams, addToRecentSearches } = usePreferencesContext();
  const { map, hasDrawnMap } = useMapContext();
  const { mapBoundary, filterBoundaries, setFilterBoundaries } = useOverlayContext();
  const { openModal } = useModalContext();
  const { user, isAuthenticated, userLocation } = useUserContext();
  const { themeName } = useThemeContext();
  const isMobile = useIsMobile();

  const [sidePanelListings, setSidePanelListings] = useState<Listing[]>(initialData.listings);
  const [clusters, setClusters] = useState<Cluster[]>(initialData.clusters);
  const [schools, setSchools] = useState<School[]>([]);
  const [hasMapMoved, setHasMapMoved] = useState(false);
  const [isMapLoading, setIsMapLoading] = useState(false);
  const [isSidebarLoading, setIsSidebarLoading] = useState(true);
  const [showsSidePanel, setShowsSidePanel] = useState(true);
  const [isSidePanelFullScreen, setIsSidePanelFullScreen] = useState(false);
  const [paginationData, setPaginationData] = useState<Meta>(initialData.meta);
  const [filters, setFilters] = useState(listingParams.filter);
  const pageParams = { page: { number: 1, size: 24 }};
  const router = useRouter();
  const isOnMap = router.route === '/search';

  const getSidebarListings = async (params: Record<string, unknown>, resetListings?: boolean) => {
    if (!isSidebarLoading) {
      setIsSidebarLoading(true);

      const { listings, meta } = await getClustersAndListings(params) as { clusters: Cluster[]; listings: Listing[]; meta: Meta };
      setPaginationData(meta);
      if (!resetListings) {
        setSidePanelListings(prev => [...prev as Listing[], ...listings]);
      } else {
        setSidePanelListings(listings);
      }

      setIsSidebarLoading(false);
    }
  };

  const { params, isLoading, updateParams } = usePaginatedQuery({ filter: { ...listingParams.filter }, sort: listingParams.sort, ...pageParams } as any, getSidebarListings as any);

  const getListingsForBoundary = async (boundaries?: Boundary[]) => {
    if (hasDrawnMap && map.longitude !== 0) {
      let boundary = null;
      if (boundaries || filterBoundaries) {
        boundaries = boundaries || filterBoundaries as Boundary[];
        if (boundaries.length > 1) {
          boundary = {
            type: 'MultiPolygon',
            coordinates: boundaries.map((bounds: any) => bounds.coordinates),
          };
        } else if (boundaries.length === 1) {
          boundary = boundaries[0];
        }
      }
      const listingParams = createListingParams();
      const params = mapSearchParams(listingParams.sort, { ...listingParams.filter, zoom: map.zoom - 1 }, boundary || mapBoundary);
      setIsMapLoading(true);
      setIsSidebarLoading(true);

      const { clusters, listings, meta } = await getClustersAndListings(params) as { clusters: Cluster[]; listings: Listing[]; meta: Meta };
      setPaginationData(meta);
      setSidePanelListings(listings);
      setClusters(clusters);
      setIsMapLoading(false);
      setIsSidebarLoading(false);

      const consolidatedFilter = deepmerge(listingParams.filter, {
        boundary: params.filter.boundary,
        slug: params.filter.slug,
        latitude: map.latitude,
        longitude: map.longitude,
        zoom: map.zoom,
      });
      listingParams.setFilter(consolidatedFilter as Filter);
      setFilters(consolidatedFilter as Filter);
    }
  };

  const fetchSchools = () => {
    return getSchools({
      page: { number: 1, size: 50 },
      filter: {
        ...schoolParams.filterParams,
        boundary: JSON.stringify(map.boundary),
      },
    }) as unknown as Promise<School[]>;
  };

  // Respond to panning on map. Get Schools and Listings in specific order depending on what filters are applied.
  const debouncedUpdateMap = useDebounce(() => {
    if (schoolParams.showSchools && schoolParams.filterListings) {
      fetchSchools().then((newSchools: School[]) => {
        const allSchoolBoundaries = newSchools.map(school => school.boundary).filter(val => val) as unknown as Boundary[];
        setSchools(newSchools);
        setFilterBoundaries(allSchoolBoundaries);
        getListingsForBoundary(allSchoolBoundaries);
      });
    } else if (schoolParams.showSchools && !schoolParams.filterListings) {
      fetchSchools().then((newSchools: School[]) => {
        setSchools(newSchools);
        getListingsForBoundary();
      });
    } else {
      getListingsForBoundary();
    }
  }, 800);

  useEffect(() => {
    if (filterBoundaries) {
      getListingsForBoundary(filterBoundaries);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterBoundaries]);

  useEffect(() => {
    if (map) {
      debouncedUpdateMap();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapBoundary, ...Object.values(listingParams.filter), ...Object.values(listingParams.filter.homeType), ...Object.values(listingParams.filter.additional.condoOrTownhouse)]);

  useEffect(() => {
    setFilters(prev => ({ ...prev, boundary: JSON.stringify(mapBoundary) }));
  }, [mapBoundary]);

  useEffect(() => {
    if (map) {
      if (!schoolParams.showSchools) {
        setFilterBoundaries([]);
        setSchools([]);
      } else if (schoolParams.showSchools) {
        fetchSchools().then(setSchools);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schoolParams.showSchools, schoolParams.eqaoRating, schoolParams.levelFilter, schoolParams.languageFilter, schoolParams.typeFilter, schoolParams.showUnrated]);

  useEffect(() => {
    trackEvent('UiSearchpView');
  }, []);

  useEffect(()=> {
    if (hasDrawnMap && map) map.onZoom(() => setHasMapMoved(true));
    if (hasDrawnMap && map) map.onPan(() => setHasMapMoved(true));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasDrawnMap]);

  const debouncedHandleQuebecPopup = useDebounce(() => {
    const isQuebec = !IsUserInQuebec(userLocation) && filters.areaName.slice(-2).toLowerCase() === 'qc';
    handleQuebecPopup({ themeName, isQuebec, openModal, source: 'map' });
  }, 300);

  useEffect(() => {
    // Handles quebec popup when user not in Quebec but searches for Quebec in map
    debouncedHandleQuebecPopup();
  }, [userLocation, filters, openModal, themeName, debouncedHandleQuebecPopup]);

  // FETCH
  const getSortedListings = async (sortBy: Sort) => {
    const boundary = filterBoundaries.length > 0 ? filterBoundaries : mapBoundary;
    const params = mapSearchParams(sortBy, { ...listingParams.filter, zoom: map.zoom }, boundary);
    getSidebarListings(params, true);
  };

  // filter bar handlers
  const onSearchResultClick = (searchPrediction: SearchPrediction) => {
    handleSearchResult({
      searchPrediction,
      listingParams,
      user,
      map,
      addToRecentSearches,
      themeName: themeName as ThemeNames,
    });
  };
  const onFiltersChanged = (changedFilters: PartialDeep<Filter>) => {
    const consolidatedFilter = deepmerge(listingParams.filter, changedFilters);
    listingParams.setFilter(consolidatedFilter as Filter);
    setFilters(consolidatedFilter as Filter);
    if (user?.id && listingParams) {
      captureLastSearch({ userId: user.id, lastSearch: listingParams, sourceTheme: themeName });
    }
  };
  const handleMoreButtonClick = () => {
    trackEvent('UiSrchQfMoreButClk');
    openModal(SAVED_SEARCH_FILTERS_MODAL, { filter: listingParams.filter, onFiltersChanged: listingParams.setFilter });
  };
  const handleSaveSearchButtonClick = () => {
    trackEvent('UiSrchQfSaveButClk');
    if (isAuthenticated) {
      openModal('create-saved-search', { query: { sort: listingParams.sort, filter: filters }});
    } else {
      openModal('login-registration');
    }
  };

  const handleMapListToggleButtonClick = () => {
    if (hasMapMoved || !listingParams.filter.slug) {
      setIsSidePanelFullScreen(prev => !prev);
    } else {
      router.push(`/${listingParams.filter.slug}-real-estate/filter?${formatQueryIntoURLQueryParams(filters)}`);
    }
  };

  return (
    <div className={styles.container}>
      {isMobile &&
      <div>
        {isOnMap && isMobile && !isSidePanelFullScreen &&
          <MapListToggleButtonOnMap
            slug={null}
            viewType={isSidePanelFullScreen ? MAP_VIEW : LIST_VIEW}
            onClick={isMobile ? handleMapListToggleButtonClick : () => null}
            isMobile={isMobile}
          />
        }
      </div>}
      <section className={styles['top-bar']}>
        <ListingFilterBar
          onLocationSearchClick={onSearchResultClick}
          filters={listingParams.filter}
          onValueChange={onFiltersChanged}
          onSaveSearchButtonClick={handleSaveSearchButtonClick}
          onMoreButtonClick={handleMoreButtonClick}
          mapListToggleViewType={isSidePanelFullScreen ? MAP_VIEW : LIST_VIEW}
          onMapListToggleButtonClick={handleMapListToggleButtonClick}
        />
      </section>
      <section className={styles['bottom-section']}>
        <SidePanel
          showsSidePanel={showsSidePanel}
          setShowsSidePanel={setShowsSidePanel}
          fullScreenPanel={isSidePanelFullScreen}
          listings={sidePanelListings}
          isLoading={isSidebarLoading || isMapLoading || isLoading}
          params={params}
          paginationData={paginationData}
          updateParams={updateParams}
          getSortedListings={getSortedListings}
          isMobile={isMobile}
          onSaveSearchButtonClick={handleSaveSearchButtonClick}
          handleMapListToggleButtonClick={handleMapListToggleButtonClick}
        />
        {map ?
          <Map schools={schools} clusters={clusters} showsLoading={isMapLoading && !isSidePanelFullScreen} showsSidePanel={showsSidePanel} isSidePanelFullScreen={isSidePanelFullScreen} />
          :
          <section className={styles['loading-map']} />
        }
      </section>
    </div>
  );
};

export default Search;
