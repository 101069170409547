import React from 'react';
import { useThemeContext } from 'contexts';
import { ThemeNames } from 'types/themes';

interface Props {
  className?: string;
  isExpTheme?: boolean;
}

const CompassArrowIcon = ({ className, isExpTheme }: Props) => {
  const { themeName } = useThemeContext();
  const isExpThemeFromContext = themeName && themeName !== ThemeNames.ZOOCASA;

  return (
    <>
      { !(isExpTheme || isExpThemeFromContext) ? (
        <svg width="34px" height="34px" className={className} viewBox="0 0 34 34" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g id="🌀-Iconography" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Elements-/-Iconography" transform="translate(-1074.000000, -1133.000000)" fill="#000000" fillRule="nonzero">
              <g id="compass" transform="translate(1074.000000, 1133.000000)">
                <path d="M30.1583822,0.10291979 L0.828756553,12.1014037 C-0.404775886,12.6060274 -0.216237087,14.4085496 1.09503558,14.646957 L14.8518063,17.1482034 L17.3530544,30.9049651 C17.5914619,32.2162369 19.3939853,32.4047756 19.8986094,31.171244 L31.8971011,1.84163759 C32.3446699,0.747525838 31.2524947,-0.344711147 30.1583822,0.10291979 Z M19.1595773,25.9357181 L17.3099973,15.7631285 C17.2106973,15.2168225 16.783126,14.7892515 16.2368197,14.6899516 L6.06422343,12.8403728 L28.2255627,3.77439336 L19.1595773,25.9357181 Z" id="Shape"></path>
              </g>
            </g>
          </g>
        </svg>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
          <g clipPath="url(#clip0_1244_7401)">
            <path d="M20.3444 13.6555L15.9957 24.057L14.6239 20.5215L14.3057 19.6942L13.4784 19.376L9.94288 18.0042L20.3444 13.6555ZM24.0709 9.92903L5.83459 17.5587L5.8346 18.5628L12.7572 21.2427L15.4371 28.1653H16.4412L24.0709 9.92903Z" fill="#0C0F24"/>
          </g>
          <defs>
            <clipPath id="clip0_1244_7401">
              <rect width="24" height="24" fill="white" transform="translate(17 0.029541) rotate(45)"/>
            </clipPath>
          </defs>
        </svg>
      )}
    </>
  );
};

export default CompassArrowIcon;
