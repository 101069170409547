import React, { useMemo } from 'react';
import configJSON from 'config.json';
import Search from 'components/search';
import { getServerSideProps as newSearchPageServerSideProps, SearchPageServerSideProps as NewSearchPageServerSideProps } from 'components/search-es';
import PopupContextProvider from 'contexts/popup';
import OverlayContextProvider from 'contexts/overlay';
import Layout from 'components/layout';
import HeadData from 'components/head-data';
import { getFeaturesOverrideFromRequest } from 'utils/features';
import { useFeaturesContext, useThemeContext } from 'contexts';
import searchPageServerSideProps, { SearchPageServerSideProps } from 'components/search/get-server-side-props';
import { DynamicSearchPageServerSideProps } from 'types/search_page_types';
import createListing from 'data/listing';
import { createGetServerSideProps } from 'utils/shared-getServerSideProps';
import { SearchPageModel } from 'components/search-es/search_page_model';
import { getThemeOverrideFromRequest } from 'utils/themes';
import { MLML_AGENT_COOKIE_NAME } from 'constants/cookies';
import MapContextProvider from 'contexts/map';
import { createPublicKey } from 'crypto';
import dynamic from 'next/dynamic';
import jwt from 'jsonwebtoken';
import cookie from 'cookie';

import type { GetServerSideProps, GetServerSidePropsContext, InferGetServerSidePropsType } from 'next';
import { isTenantExpInService, isTenantExpUS } from 'utils/tenant/which-tenant';

export type GetServerSidePropsReturnType = GetServerSideProps<DynamicSearchPageServerSideProps<SearchPageServerSideProps | NewSearchPageServerSideProps>>;

const NewSearch = dynamic(() => import('components/search-es'), { ssr: false });

const getServerSidePropsHandler = (async (context: GetServerSidePropsContext) => {
  const { req, res } = context;
  const featureOverrides = getFeaturesOverrideFromRequest(req);
  const features = { ...configJSON.features, ...featureOverrides };

  const themeName = getThemeOverrideFromRequest(req);
  // Check Agent link only for exprealtyUS theme
  if (isTenantExpUS(themeName, features) || isTenantExpInService(themeName, features)) {
    const isAgentToken = req.url?.includes('agent_portal_token');
    if (isAgentToken) {
      try {
        // Get token from URL
        const token = req.url?.split('agent_portal_token=')[1]?.split('&')[0];

        const agentAdminPublicKey = process.env.NEXT_PUBLIC_AGENT_ADMIN_KEY;
        if (!agentAdminPublicKey) throw new Error('Agent admin public key not found');

        // Verify token
        if (token) {
          const publicKey = createPublicKey({
            key: agentAdminPublicKey.replace(/\\n/g, '\n'),
            format: 'pem',
          });

          const decoded = jwt.verify(token, publicKey, { algorithms: ['RS256'], maxAge: '5m' });
          // Set cookie with token and expiry of 7 days
          res.setHeader('Set-Cookie', cookie.serialize(MLML_AGENT_COOKIE_NAME, JSON.stringify(decoded), {
            path: '/',
            maxAge: 604800,
          }));
        }
      } catch (error) {
        console.error('### Token verification failed:', (error as any).message);
      }
    }
  }

  if (features.useNewMapPins) {
    return await newSearchPageServerSideProps();
  } else {
    return await searchPageServerSideProps(context);
  }
});

export const getServerSideProps = createGetServerSideProps(getServerSidePropsHandler) satisfies GetServerSidePropsReturnType;

export default function SearchPage({ routeName, props }: InferGetServerSidePropsType<typeof getServerSideProps>) {
  const { theme } = useThemeContext();
  const headerData = {
    title: theme.title,
    metaDescription: theme.metaDescription,
  };

  return (
    <Layout routeName={routeName}>
      <HeadData data={headerData} />
      <PopupContextProvider>
        <OverlayContextProvider>
          <DynamicSearchPageContent routeName={routeName} props={props} />
        </OverlayContextProvider>
      </PopupContextProvider>
    </Layout>
  );
}

function DynamicSearchPageContent({ props }: DynamicSearchPageServerSideProps<SearchPageServerSideProps | NewSearchPageServerSideProps>) {
  const { features } = useFeaturesContext();
  const searchPageModel = useMemo(() => new SearchPageModel(features.useLegacySearchFilter), [features.useLegacySearchFilter]);

  if (features.useNewMapPins) {
    return <>
      <NewSearch model={searchPageModel} />
    </>;
  } else {
    const { clusters, listingsData, meta } = props as SearchPageServerSideProps;
    const listings = listingsData.map(i => new createListing(i));
    return <>
      <MapContextProvider>
        <Search initialData={{ clusters, listings, meta }} />
      </MapContextProvider>
    </>;
  }
}