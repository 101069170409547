import React, { CSSProperties, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { PreferencesContext } from 'contexts/preferences';
import SelectField from 'components/control/select-field';
import ListingCard from 'components/listing-card';
import WarningIcon from 'components/icon/warning-icon';
import { buildClassName } from 'utils/build-class-name';
import { sortOptions } from 'utils/select-options';
import mapSearchParams from 'utils/search-params';
import { IOverlayContext, OverlayContext } from 'contexts/overlay';
import { IMapContext, MapContext } from 'contexts/map';
import { FixedSizeGrid as Grid } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import InfiniteLoader from 'react-window-infinite-loader';
import useWindowWidth from 'hooks/use-window-width';
import LoadingSkeleton from 'components/loading-skeleton';
import { formatNumberWithSuffix } from 'utils/format-number-with-suffix';
import styles from './style.module.scss';
import { dynamicTestIds, mapPageIds } from 'constants/test-constants';
import Button, { PRIMARY_THEME, TERTIARY_THEME } from 'components/control/button';
import LeftArrowIcon from 'components/icon/left-arrow-icon';
import RightArrowIcon from 'components/icon/right-arrow-icon';
import FilterIcon from 'components/icon/filter-icon';
import BellIcon from 'components/icon/bell-icon';
import { isUsStateCode, ProvinceOrStateCode } from 'utils/province_or_state';
import ProviderDisclaimer from 'components/provider-disclaimer';
import convertCssStringPropToNumber from 'utils/convert-css-prop';
import prioritizeFeeds from 'utils/prioritize-feeds';
import { useModalContext, useUserContext, useThemeContext, SAVED_SEARCH_FILTERS_MODAL } from 'contexts';
import { captureLastSearch } from 'pages/api/last-search/lastSearchApiClient';
import { trackEvent } from 'utils/google-tag-manager';
import { GTM_CLICK_LISTING_CARD } from 'constants/events';
import { searchFilterIds } from 'constants/test-constants';
import MobileBottomNav from 'components/header/zoo/mobile-bottom-nav';

import type { IPreferences } from 'contexts/preferences';
import type { Sort } from 'contexts/preferences/listing-params/types';
import type { Meta } from 'utils/types';
import type { AreaPageListingCardData } from 'components/dynamic-page/new-area-listings-page/area_page_listing_card_data';
import type Listing from 'data/listing';

interface Props {
  listings: AreaPageListingCardData[] | Listing[];
  isLoading: boolean;
  params: any;
  updateParams: any;
  getSortedListings: (sort: Sort) => void;
  paginationData: Meta;
  showsSidePanel: boolean;
  setShowsSidePanel: (showsSidePanel: boolean) => void;
  fullScreenPanel: boolean;
  isMobile: boolean;
  onSaveSearchButtonClick: () => void;
  handleMapListToggleButtonClick: () => void;
}

/** @deprecated use components from `components/search-es` instead */
const SidePanel = ({ listings, showsSidePanel, setShowsSidePanel, fullScreenPanel, isLoading, updateParams, getSortedListings, paginationData, isMobile, onSaveSearchButtonClick, handleMapListToggleButtonClick }: Props) => {
  const { listingParams } = useContext(PreferencesContext) as IPreferences;
  const { user } = useUserContext();
  const { themeName } = useThemeContext();
  const { map } = useContext(MapContext) as IMapContext;
  const { openModal } = useModalContext();
  const { mapBoundary, filterBoundaries } = useContext(OverlayContext) as IOverlayContext;
  const infiniteLoaderRef = useRef(null);
  const windowWidth = useWindowWidth();
  const cardSize = {
    height: isMobile && (windowWidth < 600 && windowWidth > 400) ? (windowWidth - 16) * 1.1 : 400,
    width: isMobile && windowWidth < 600 ? windowWidth - 16 : 290,
  };
  const isSidePanelEnlarged = isMobile || windowWidth >= convertCssStringPropToNumber(styles.sidePanelBreakpoint);
  const [numberOfColumns, setNumberOfColumns] = useState(0);
  const rowCount = Math.round(paginationData.totalCount / numberOfColumns) + 1;
  const [xOffset, setXOffset] = useState(0);

  const moreListingsAvailableToLoad = paginationData.totalCount > listings.length;
  const hasListings = listings.length > 0;
  const containsUSListings = listings.some(item => isUsStateCode(item.province?.toLowerCase() as ProvinceOrStateCode));
  const [disclaimerRead, setDisclaimerRead] = useState(false);
  const showsDisclaimer = containsUSListings && !disclaimerRead;

  // Control scroll of virtualized list with private function
  useEffect(() => {
    if (infiniteLoaderRef?.current) {
      const listReference = (infiniteLoaderRef?.current as any)?._listRef as Record<string, any>;
      if (listReference) {
        listReference.scrollToItem(0);
      }
    }
  }, [mapBoundary]);

  const loadMore = () => {
    if (!showsDisclaimer) {
      return new Promise(resolve => {
        const boundary = filterBoundaries.length ? filterBoundaries : mapBoundary;
        const params = mapSearchParams(listingParams?.sort, { ...listingParams.filter, zoom: map.zoom }, boundary);
        updateParams({ ...params, page: { number: paginationData.pageNumber + 1, size: params.page.size }});
        resolve();
      })as Promise<void>;
    }
  };

  const isItemLoaded = (index: number) => !moreListingsAvailableToLoad || index < listings.length;

  const calculateGridProperties = useCallback(() => {
    const widthOfCard = 290;
    let padding = 0;
    const panelWidth = isMobile? windowWidth - 16 : isSidePanelEnlarged ? convertCssStringPropToNumber(styles.sidePanelEnlarged) : convertCssStringPropToNumber(styles.sidePanelSmall);
    let widthOfPaddedCardOffset;
    if (fullScreenPanel && !isMobile) {
      widthOfPaddedCardOffset = 8;
      padding = windowWidth < 1300 ? 168 : 288;
    } else {
      widthOfPaddedCardOffset = isSidePanelEnlarged ? 5 : 10;
    }
    const widthOfPaddedCard = widthOfCard + widthOfPaddedCardOffset;
    const availableGridWidth = (fullScreenPanel && !isMobile) ? windowWidth - padding : panelWidth - padding;
    const numberOfColumns = isMobile ? 1: Math.floor(availableGridWidth / widthOfCard);
    const widthOfGrid = fullScreenPanel ? ((windowWidth - numberOfColumns * widthOfPaddedCard) / 2) : ((panelWidth - numberOfColumns * widthOfPaddedCard) / 2) ;

    return { widthOfGrid, numberOfColumns };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullScreenPanel, isSidePanelEnlarged, windowWidth]);

  useEffect(() => {
    const { widthOfGrid, numberOfColumns } = calculateGridProperties();
    setXOffset(isMobile && windowWidth < 420 ? 8 : widthOfGrid);
    setNumberOfColumns(numberOfColumns);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowWidth, fullScreenPanel, calculateGridProperties]);

  useEffect(() => {
    if (infiniteLoaderRef.current) {
      (infiniteLoaderRef.current as any).resetloadMoreItemsCache();
      const listReference = (infiniteLoaderRef?.current as any)?._listRef as Record<string, any>;
      if (listReference) {
        listReference.scrollToItem(0);
      }
    }
  }, [listingParams.sort]);

  const getLinearIndexFromGridIndices = useCallback((column: number, row: number) => {
    let index = 0;
    if (row === 0) {
      index = column;
    } else {
      const previousListings = numberOfColumns * row;
      index = previousListings + column;
    }
    return index;
  }, [numberOfColumns]);

  const getGridWidth = (width: number) => (fullScreenPanel && !isMobile) ? windowWidth - (xOffset as number) : width - 10;
  const getGridHeight = (height: number) => {
    const sidePanelHeaderHeight = isSidePanelEnlarged ? 71 : 96;
    return height - sidePanelHeaderHeight;
  };

  const ListingCardGrid = useCallback(({ columnIndex, rowIndex, style }: { columnIndex: number; rowIndex: number; style: CSSProperties}) => {
    style = { ...style, padding: '.5em' };
    const listingIndex = getLinearIndexFromGridIndices(columnIndex, rowIndex);
    if (!listings[listingIndex]) {
      if (showsDisclaimer && listingIndex === listings.length) { // adding an extra item
        const providers = prioritizeFeeds([...new Set(listings.map(l => l.providerId))]);
        const isEven = listings.length % 2 === 0;
        const commonStyle = { width: 'auto', height: 'auto', padding: 0, left: 0 };
        const topForOddCol = isSidePanelEnlarged ? cardSize.height * (rowIndex + 1) : cardSize.height * rowIndex;
        const topForEvenCol = style.top;
        return (
          <div style={{ ...style, ...commonStyle, top: !isEven ? topForOddCol : topForEvenCol }}>
            {moreListingsAvailableToLoad &&
              <div className={styles['disclaimer-button-wrapper']}>
                <Button label='View more listings' theme='primary' onClick={() => setDisclaimerRead(true)} />
              </div>
            }
            {providers.map(p => <ProviderDisclaimer key={p} providerId={p} tenantName={themeName}/>)}
          </div>
        );
      }
      return null;
    } else if (!isLoading) {
      return <div style={style} onClick={() => trackEvent(GTM_CLICK_LISTING_CARD)}><ListingCard listing={listings[listingIndex]} isOnMapSidePanel={true} /></div>;
    } else {
      return <div style={style}><LoadingSkeleton /></div>;
    }
  }, [listings, isLoading, isSidePanelEnlarged, showsDisclaimer, getLinearIndexFromGridIndices, moreListingsAvailableToLoad, cardSize.height]);

  const togglePanel = () => setShowsSidePanel(!showsSidePanel);
  return (showsSidePanel ?
    <div id='map-side-panel' className={buildClassName(styles.component, fullScreenPanel && styles['full-screen'], (isMobile && showsSidePanel && fullScreenPanel) && styles['is-mobile'])} data-testid={mapPageIds.listingsSidebar} dynamic-testid={(fullScreenPanel ? dynamicTestIds.fullScreenListings : dynamicTestIds.sidebarListings)}>
      {isMobile ?
        <section className={styles['mobile-listing-filters']}>
          <div className={styles['listing-header']}>
            {formatNumberWithSuffix(paginationData.totalCount, false)} Homes in Map Area
          </div>
          <div className={styles['input-wrapper']}>
            <SelectField
              value={listingParams?.sort}
              className='sort-order'
              options={sortOptions(listingParams.filter.status)}
              onValueChange={(sort: Sort) => {
                listingParams.setSort(sort);
                if (user?.id && listingParams) {
                  captureLastSearch({ userId: user.id, lastSearch: listingParams, sourceTheme: themeName });
                }
                getSortedListings(sort);
              }}
            />
            <Button
              Icon={FilterIcon}
              theme={TERTIARY_THEME}
              label="Filters"
              onClick={() => openModal(SAVED_SEARCH_FILTERS_MODAL, { filter: listingParams.filter, onFiltersChanged: listingParams.setFilter })}
              testId={searchFilterIds.mobileFilterButton}
              className={styles['padded-button']}
            />
            <Button
              Icon={BellIcon}
              theme={PRIMARY_THEME}
              label="Save Search"
              onClick={onSaveSearchButtonClick}
              testId={searchFilterIds.saveSearchButtonMapPage}
              className={styles['save-search']}
            />
          </div>
        </section>
        : <section className={styles.sort} style={{ transform: fullScreenPanel && !isMobile ? `translateX(${xOffset}px)` : 'translateX(10px)' }}>
          <div className={styles['listing-count']} data-testid={mapPageIds.listingsCount}>
            {formatNumberWithSuffix(paginationData.totalCount, false)} Listings
          </div>
          <div className={styles['sort-input-wrapper']}>
            <SelectField
              value={listingParams?.sort}
              options={sortOptions(listingParams.filter.status)}
              onValueChange={(sort: Sort) => {
                listingParams.setSort(sort);
                if (user?.id && listingParams) {
                  captureLastSearch({ userId: user.id, lastSearch: listingParams, sourceTheme: themeName });
                }
                getSortedListings(sort);
              }}
            />
          </div>
          {!fullScreenPanel && isSidePanelEnlarged &&
            <div className={styles['hide-panel-wrapper']}>
              <Button label="Hide Side List" theme='secondary' Icon={LeftArrowIcon} onClick={togglePanel} />
            </div>
          }
        </section>}
      <section className={styles.scrollable}>
        <AutoSizer data-testid={mapPageIds.listingsScrollableList} style={{ transform: fullScreenPanel && !isMobile ? `translateX(${xOffset}px` : 'translateX(8px)', paddingBottom: '1em' }}>
          {({ width, height }: { width: number; height: number }) => (
            <InfiniteLoader
              ref={infiniteLoaderRef}
              isItemLoaded={isItemLoaded}
              itemCount={paginationData.totalCount}
              threshold={10}
              loadMoreItems={loadMore}
            >
              {({ onItemsRendered, ref }) => {
                return (
                  <Grid
                    className={styles['list-grid']}
                    rowCount={rowCount}
                    columnCount={numberOfColumns}
                    columnWidth={cardSize.width}
                    rowHeight={cardSize.height}
                    width={getGridWidth(width)}
                    height={getGridHeight(height)}
                    ref={ref}
                    onItemsRendered={gridProps => {
                      onItemsRendered({
                        overscanStartIndex: gridProps.overscanRowStartIndex * numberOfColumns,
                        overscanStopIndex: gridProps.overscanRowStopIndex * numberOfColumns,
                        visibleStartIndex: gridProps.visibleRowStartIndex * numberOfColumns,
                        visibleStopIndex: gridProps.visibleRowStopIndex * numberOfColumns,
                      });
                    }}
                  >
                    {ListingCardGrid}
                  </Grid>
                );
              }}
            </InfiniteLoader>
          )}
        </AutoSizer>
        {isMobile && fullScreenPanel && <div className={styles['mobile-nav']}><MobileBottomNav handleMapListToggleButtonClick={handleMapListToggleButtonClick} /></div>}
      </section>
      {(!isLoading && !hasListings) && <div className={styles['empty-state']}> <WarningIcon className={styles['empty-warning']} /> There are no homes that match your criteria. Try moving the map, searching another location or revising your filter options. </div>}
    </div>
    :
    <div className={styles['show-panel-wrapper']}>
      <Button label="Show Side List" theme='secondary' RightIcon={RightArrowIcon} onClick={togglePanel} />
    </div>
  );
};

export default SidePanel;
