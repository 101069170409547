import React, { ReactNode, useContext } from 'react';
import FilterIcon from 'components/icon/filter-icon';
import CompassArrowIcon from 'components/icon/compass-arrow-icon';
import { MapContext } from 'contexts/map';
import { PreferencesContext } from 'contexts/preferences';
import { ModalContext, SAVED_SEARCH_FILTERS_MODAL } from 'contexts/modal';
import { buildClassName } from 'utils/build-class-name';
import SchoolHat from './school-hat';
import { useIsMobile } from 'hooks/use-size-class';
import { IPopupContext, PopupContext } from 'contexts/popup';
import styles from './style.module.scss';
import { searchFilterIds } from 'constants/test-constants';

import type { IModalContext } from 'contexts/modal';
import type { IMapContext } from 'contexts/map';
import type { IPreferences } from 'contexts/preferences';

const MapControls = ({ isSidePanelFullScreen }: { isSidePanelFullScreen: boolean }) => {
  const { map } = useContext(MapContext) as IMapContext;
  const { clusterPopupData, schoolPopupData } = useContext(PopupContext) as IPopupContext;
  const { schoolParams, listingParams } = useContext(PreferencesContext) as IPreferences;
  const { openModal } = useContext(ModalContext) as IModalContext;

  const isMobile = useIsMobile();

  const isMobileSlideupOpen = isMobile && (clusterPopupData || schoolPopupData);

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const { coords } = position;
        map.changeLocation({ lng: coords.longitude, lat: coords.latitude }, 14);
      }, () => {
      });
    }
  };

  return !map ? null : (
    <div className={buildClassName(styles.component, (isMobileSlideupOpen || isSidePanelFullScreen) && styles['not-visible'])}>
      <ControlButton desktopOnly={true} handler={() => map.zoomIn()} testId={searchFilterIds.mapZoomIn}>
         +
      </ControlButton>
      <ControlButton desktopOnly={true} handler={() => map.zoomOut()}>
         -
      </ControlButton>
      <ControlButton mobileOnly={true} handler={() => openModal(SAVED_SEARCH_FILTERS_MODAL, { filter: listingParams.filter, onFiltersChanged: listingParams.setFilter })}>
        <FilterIcon className={styles.filter} />
      </ControlButton>
      <ControlButton handler={() => openModal('school-filters')} isActive={schoolParams.showSchools} testId={searchFilterIds.showSchoolPins}>
        <SchoolHat />
      </ControlButton>
      <ControlButton handler={getUserLocation}>
        <CompassArrowIcon className={styles['compass-arrow']} />
      </ControlButton>
    </div>
  );
};

interface ControlButtonProps {
  handler: () => void;
  children: ReactNode;
  desktopOnly?: boolean;
  mobileOnly?: boolean;
  isActive?: boolean;
  testId?: string;
}

const ControlButton = ({ handler, children, desktopOnly, mobileOnly, isActive, testId }: ControlButtonProps) => {
  return (
    <span onClick={handler} className={buildClassName(desktopOnly && styles['desktop-only'], mobileOnly && styles['mobile-only'], isActive && styles.active)} data-testid={testId}>
      {children}
    </span>
  );
};


export default MapControls;
